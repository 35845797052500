import React, { Component } from "react"
import PageTitleArea from "../components/Common/PageTitleArea"
import Layout from "../components/layout"

class Videos extends Component {
  render() {
    return (
      <React.Fragment>
        <Layout title="Videos" description="Want to learn how to use HASS, HASS House, or COOSA?  Start here.">
          <PageTitleArea
            pageTitle="Training Videos"
            pageDescription="Learn to use many of the features of HASS"
          />
          <div className="row pb-3 pt-3">
            <div className="col-12 d-flex justify-content-center">
              <p>
                Don't see a video that you would like? Please tell us! Submit a
                support ticket or email us!
              </p>
            </div>
          </div>
          <div className="row pb-100 pt-1">
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 1"
                src="https://www.youtube.com/embed/8mK0VhUlZdo"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 2"
                src="https://www.youtube.com/embed/VNoBu-8lu8A"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 3"
                src="https://www.youtube.com/embed/jF3ktZv6c08"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 11"
                src="https://www.youtube.com/embed/Ox1b-fFXS4w"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 4"
                src="https://www.youtube.com/embed/6dkvW2-3KEA"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 4"
                src="https://www.youtube.com/embed/iISCCWPhqWE"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 12"
                src="https://www.youtube.com/embed/bgiAMv_7-AY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 5"
                src="https://www.youtube.com/embed/DtH-bnruJW0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 6"
                src="https://www.youtube.com/embed/lAAmYXbux3g"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 7"
                src="https://www.youtube.com/embed/mheLWTFFlGY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 8"
                src="https://www.youtube.com/embed/0n2e5s--IPg"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 9"
                src="https://www.youtube.com/embed/HzDLtCwDX2k"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-center pb-4">
              <iframe
                width="560"
                height="315"
                title="HASS Training Video 10"
                src="https://www.youtube.com/embed/SndhCHrEoFM"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default Videos
